import dayjs from "dayjs";
import {
  AnalysisStatus,
  FeedbackAnalysisItem,
  FeedbackSource,
  FeedbackSourceExtra,
  FeedbackSourceExtraApp,
  FeedbackSourceExtraFivetran,
  FeedbackSourceExtraGroup,
  FeedbackSourceExtraMerge,
  FeedbackSourceExtraSurvey,
  FeedbackSourceExtraZapier,
  FeedbackSourceExtraZapierTranscripts,
  FeedbackSourceType,
} from "integrations/klassy/feedback-api-v2-schema";
import { reverse, sortBy } from "lodash";
import { z } from "zod";

import { AnalysisType, FeedbackSourceData, FeedbackSources } from "app/core/klever/feedback/types";
import { DateRange, getDateStartAndEnd } from "app/core/utils/dates";

import { GetFeedbackSourcesResponse } from "app/product-research/queries/getFeedbackSources";

import { SourceContentType, SourceOriginServiceType } from "app/sources/types/shared";

import { getAppstoreType } from "../../../utils/common";

import { FeedbackReviewSummaryData, FeedbackSourceApp, FeedbackSourceNames } from "./types";

export const isFeedbackSourceApp = (x: any): x is FeedbackSourceApp => {
  return x && x.type === "app";
};

export const isFeedbackSourceAppNew = (x: any): x is FeedbackSourceData<FeedbackSourceExtraApp> => {
  return x && x.type === "appNew";
};

export const isFeedbackSourceIosOrGplay = (
  x: any
): x is FeedbackSourceData<FeedbackSourceExtraApp> => {
  return x && (x.type === "ios" || x.type === "google_play");
};

export const isFeedbackSourceAllSources = (x: any): x is FeedbackSourceApp => {
  return x && x.type === "all";
};

export const isFeedbackSourceZendesk = (x: any): x is FeedbackSourceData => {
  return x && x.type === "zendesk";
};

export const isFeedbackSourceFreshdesk = (x: any): x is FeedbackSourceData => {
  return x && x.type === "freshdesk";
};

export const isFeedbackSourceHubspot = (x: any): x is FeedbackSourceData => {
  return x && x.type === "hubspot";
};

export const isFeedbackSourceDelighted = (x: any): x is FeedbackSourceData => {
  return x && x.type === "delighted";
};

export const isFeedbackSourceDiscord = (x: any): x is FeedbackSourceData => {
  return x && x.type === "discord";
};

export const isFeedbackSourceDovetail = (x: any): x is FeedbackSourceData => {
  return x && x.type === "dovetail";
};

export const isFeedbackSourceZapierZendesk = (x: any): x is FeedbackSourceData => {
  return x && x.type === "zapier_zendesk";
};

export const isFeedbackSourceZapierIntercom = (x: any): x is FeedbackSourceData => {
  return x && x.type === "zapier_intercom";
};

export const isFeedbackSourceFireflies = (
  x: any
): x is FeedbackSourceData<FeedbackSourceExtraZapierTranscripts> => {
  return x && x.data.extra?.ingestionApi && x.data.extra?.ingestionType === "fireflies.ai";
};

export const isFeedbackSourceFathom = (
  x: any
): x is FeedbackSourceData<FeedbackSourceExtraZapierTranscripts> => {
  return x && x.data.extra?.ingestionApi && x.data.extra?.ingestionType === "fathom";
};

export const isFeedbackSourceFront = (x: any): x is FeedbackSourceData => {
  return x && x.type === "front";
};

export const isFeedbackSourceHelpscout = (x: any): x is FeedbackSourceData => {
  return x && x.type === "helpscout";
};

export const isFeedbackSourceSlack = (x: any): x is FeedbackSourceData => {
  return x && x.type === "slack";
};

export const isFeedbackSourceZoom = (x: any): x is FeedbackSourceData => {
  return x && x.type === "zoom";
};

export const isFeedbackSourceIntercom = (x: any): x is FeedbackSourceData => {
  return x && x.type === "intercom";
};

export const isFeedbackSourceStackOverflow = (x: any): x is FeedbackSourceData => {
  return x && x.type === "stackOverflow";
};

export const isFeedbackSourceTwitter = (x: any): x is FeedbackSourceData => {
  return x && x.type === "twitter";
};

export const isFeedbackSourceJira = (x: any): x is FeedbackSourceData => {
  return x && x.type === "jira";
};

export const isFeedbackSourceG2 = (x: any): x is FeedbackSourceData => {
  return x && x.type === "g2";
};

export const isFeedbackSourceTrustpilot = (x: any): x is FeedbackSourceData => {
  return x && x.type === "trustpilot";
};

export const isFeedbackSourceFreeFormText = (x: any): x is FeedbackSourceData => {
  return x && x.type === "free-form-text";
};

export const isFeedbackSourceGoogleMeet = (
  x: any
): x is FeedbackSourceData<FeedbackSourceExtraZapierTranscripts> => {
  return (
    x &&
    ((x.data.extra?.ingestionApi && !x.data.extra?.ingestionType) ||
      (x.data.extra?.ingestionApi && x.data.extra?.ingestionType === "googlemeet"))
  );
};

export const isFeedbackSourceCsv = (x: any): x is FeedbackSourceData => {
  return x && x.type === "csv";
};

export const isFeedbackSourceGithub = (x: any): x is FeedbackSourceData => {
  return x && x.type === "github";
};

export const isFeedbackSourceKustomer = (x: any): x is FeedbackSourceData => {
  return x && x.type === "kustomer";
};

export const isFeedbackSourceGongCalls = (x: any): x is FeedbackSourceData => {
  return x && x.type === "gongCalls";
};

export const isFeedbackSourceSalesForce = (x: any): x is FeedbackSourceData => {
  return x && x.type === "salesforce";
};

export const isFeedbackSourceDixa = (x: any): x is FeedbackSourceData => {
  return x && x.type === "dixa";
};

export const isFeedbackSourceHackerNews = (x: any): x is FeedbackSourceData => {
  return x && x.type === "hackerNews";
};

export const isFeedbackSourceFacebook = (x: any): x is FeedbackSourceData => {
  return x && x.type === "facebook";
};

export const isFeedbackSourceInstagram = (x: any): x is FeedbackSourceData => {
  return x && x.type === "instagram";
};

export const isFeedbackSourceZapier = (
  x: any
): x is FeedbackSourceData<FeedbackSourceExtraZapier> => {
  return x && x.type === "zapier";
};

export const isFeedbackSourceGroup = (
  x: any
): x is FeedbackSourceData<FeedbackSourceExtraGroup> => {
  return x && x.type === "group";
};

export const isFeedbackSourceSurvey = (
  x: any
): x is FeedbackSourceData<FeedbackSourceExtraSurvey> => {
  return x && x.type === "survey";
};

export const isMergeFeedbackSource = (
  x: any
): x is FeedbackSourceData<FeedbackSourceExtraMerge> => {
  return (
    x &&
    x.type !== "app" &&
    x.type !== "appNew" &&
    x.type !== FeedbackSourceType.zapier &&
    x.type !== FeedbackSourceType.csv &&
    x.type !== FeedbackSourceType.all &&
    x.type !== FeedbackSourceType.group
  );
};

export const isFeedbackSourceZapierAllTypes = (type: string): boolean => {
  return (
    type === FeedbackSourceType.zapier ||
    type === FeedbackSourceType.delighted ||
    type === FeedbackSourceType.discord ||
    type === FeedbackSourceType.hubspot ||
    type === FeedbackSourceType.dovetail ||
    type === FeedbackSourceType.gongCalls ||
    type === FeedbackSourceType.zapier_intercom ||
    type === FeedbackSourceType.zapier_zendesk ||
    type === FeedbackSourceType.intercom ||
    type === FeedbackSourceType.zendesk ||
    type === FeedbackSourceType.front ||
    type === FeedbackSourceType.helpscout ||
    type === FeedbackSourceType.slack
  );
};

export const isApifySource = (type: FeedbackSourceType): boolean => {
  return (
    type === FeedbackSourceType.twitter ||
    type === FeedbackSourceType.g2 ||
    type === FeedbackSourceType.trustpilot ||
    type === FeedbackSourceType.reddit ||
    type === FeedbackSourceType.amazon
  );
};

export const isFeedbackSourceEmbedZapier = (type: string): boolean => {
  return (
    type === FeedbackSourceType.hubspot ||
    type === FeedbackSourceType.zapier_intercom ||
    type === FeedbackSourceType.front ||
    type === FeedbackSourceType.zapier_zendesk ||
    type === FeedbackSourceType.helpscout ||
    type === FeedbackSourceType.slack ||
    type === FeedbackSourceType.discord ||
    type === FeedbackSourceType.dovetail ||
    type === FeedbackSourceType.delighted ||
    type === FeedbackSourceType.zoom ||
    type === FeedbackSourceType.intercom ||
    type === FeedbackSourceType.googlemeet
  );
};

export const isSameSource = (x: FeedbackSources, y: FeedbackSources): boolean => {
  return x.type === y.type && x.data.id === y.data.id;
};

export const areSourceTypesAllCsvFreeForm = (sources, selectedSources): boolean => {
  const selectedSourcesById = selectedSources.data.extra.upstreamSourceIds;
  let allCsvFreeForm = true;
  selectedSourcesById.forEach((sourceId) => {
    if (
      sources.get(sourceId)?.type !== FeedbackSourceType.csv &&
      sources.get(sourceId)?.type !== FeedbackSourceType.freeFormText
    ) {
      allCsvFreeForm = false;
      return;
    }
  });
  return allCsvFreeForm;
};

export const isDateFilterSource = (source: FeedbackSources): boolean => {
  if (source.type === FeedbackSourceType.csv || source.type === FeedbackSourceType.freeFormText) {
    return false;
  }

  return true;
};

export const getGroupSourcesNames = (groupedSources, sources): FeedbackSource[] => {
  const sourcesById = groupedSources.data.extra.upstreamSourceIds;
  return sources
    .filter((source) => sourcesById.includes(source.data.id))
    .map((source) => getSourceNameOld(source, false));
};

export const getSourceCreatedDateRange = (source: FeedbackSources): DateRange => {
  return getDateStartAndEnd(source.createdAt);
};

export const isStaticSource = (type: string): boolean => {
  return (
    type === FeedbackSourceType.csv ||
    type === FeedbackSourceType.freeFormText ||
    type === FeedbackSourceType.zoom
  );
};

export const getSourceType = (source: FeedbackSources) => {
  if (isFeedbackSourceAppNew(source)) return FeedbackSourceType.appNew;
  if (isFeedbackSourceAllSources(source)) return FeedbackSourceType.all;
  if (isFeedbackSourceZendesk(source)) return FeedbackSourceType.zendesk;
  if (isFeedbackSourceFront(source)) return FeedbackSourceType.front;
  if (isFeedbackSourceIntercom(source)) return FeedbackSourceType.intercom;
  if (isFeedbackSourceJira(source)) return FeedbackSourceType.jira;
  if (isFeedbackSourceFreeFormText(source)) return FeedbackSourceType.freeFormText;
  if (isFeedbackSourceCsv(source)) return FeedbackSourceType.csv;
  if (isFeedbackSourceG2(source)) return FeedbackSourceType.g2;
  if (isFeedbackSourceFreshdesk(source)) return FeedbackSourceType.freshdesk;
  if (isFeedbackSourceHubspot(source)) return FeedbackSourceType.hubspot;
  if (isFeedbackSourceGithub(source)) return FeedbackSourceType.github;
  if (isFeedbackSourceKustomer(source)) return FeedbackSourceType.kustomer;
  if (isFeedbackSourceStackOverflow(source)) return FeedbackSourceType.stackOverflow;
  if (isFeedbackSourceTwitter(source)) return FeedbackSourceType.twitter;
  if (isFeedbackSourceSalesForce(source)) return FeedbackSourceType.salesforce;
  if (isFeedbackSourceDixa(source)) return FeedbackSourceType.dixa;
  if (isFeedbackSourceHackerNews(source)) return FeedbackSourceType.hackerNews;
  if (isFeedbackSourceGongCalls(source)) return FeedbackSourceType.gongCalls;
  if (isFeedbackSourceFacebook(source)) return FeedbackSourceType.facebook;
  if (isFeedbackSourceInstagram(source)) return FeedbackSourceType.instagram;
  if (isFeedbackSourceDelighted(source)) return FeedbackSourceType.delighted;
  if (isFeedbackSourceDiscord(source)) return FeedbackSourceType.discord;
  if (isFeedbackSourceDovetail(source)) return FeedbackSourceType.dovetail;
  if (isFeedbackSourceFireflies(source)) return FeedbackSourceType.fireflies;
  if (isFeedbackSourceZapierZendesk(source)) return FeedbackSourceType.zapier_zendesk;
  if (isFeedbackSourceZapierIntercom(source)) return FeedbackSourceType.zapier_intercom;
  if (isFeedbackSourceFathom(source)) return FeedbackSourceType.fathom;
  if (isFeedbackSourceZapier(source)) return FeedbackSourceType.zapier;
  if (isFeedbackSourceGroup(source)) return FeedbackSourceType.group;
  if (isFeedbackSourceSurvey(source)) return FeedbackSourceType.survey;
  if (isFeedbackSourceTrustpilot(source)) return FeedbackSourceType.trustpilot;
  if (isFeedbackSourceHelpscout(source)) return FeedbackSourceType.helpscout;
  if (isFeedbackSourceSlack(source)) return FeedbackSourceType.slack;
  if (isFeedbackSourceZoom(source)) return FeedbackSourceType.zoom;

  throw new Error("Invalid source");
};

export const getSourceTypeWithApp = (
  source: FeedbackSources
): FeedbackSourceType | "ios" | "google_play" => {
  if (
    isFeedbackSourceAppNew(source) ||
    isFeedbackSourceApp(source) ||
    isFeedbackSourceIosOrGplay(source)
  ) {
    return getAppstoreType(source.data.extra.appId);
  }
  return getSourceType(source);
};

export const getSourceName = (
  originService: SourceOriginServiceType,
  contentType: SourceContentType,
  name: string,
  extra: FeedbackSourceExtra,
  sourceCreatedAt?: Date,
  withCountry = true,
  withDate = true
): string => {
  const base = (() => {
    switch (originService) {
      case "IOS":
      case "ANDROID":
        const { country } = extra as FeedbackSourceExtraApp;
        return typeof country === "string" && withCountry
          ? `${name} (${country.toUpperCase()})`
          : name ?? "?";
      case "UNKNOWN":
        return name ?? contentType === "FREE_FORM_TEXT"
          ? "Text"
          : contentType === "CSV"
          ? "CSV"
          : name ?? "";
      case "G2":
      case "REDDIT":
      case "AMAZON":
      case "TRUSTPILOT":
      case "TWITTER":
        if ("productSlug" in extra) {
          return `${FeedbackSourceNames[originService.toLowerCase()]} - ${extra.productSlug}`;
        }
        return name ?? "";
      case "INTERCOM":
      case "ZENDESK":
        if (extra != null && "tags" in extra) {
          const sourceExtra = extra as FeedbackSourceExtraFivetran;
          const retrievedTags = sourceExtra?.tags ?? [];
          if (retrievedTags.length > 0) {
            return name && name.trim() !== ""
              ? name
              : `${FeedbackSourceNames[originService.toLowerCase()]} (tags: ${retrievedTags.join(
                  ","
                )})`;
          }
        }
        return name && name.trim() !== ""
          ? name
          : `${FeedbackSourceNames[originService.toLowerCase()]}`;
      case "GONG":
        return name && name.trim() !== "" ? name : FeedbackSourceNames["gongCalls"];
      case "HELP_SCOUT":
        return name && name.trim() !== "" ? name : FeedbackSourceNames["helpscout"];
      case "GOOGLE_MEET":
        return name && name.trim() !== "" ? name : FeedbackSourceNames["googlemeet"];
      default:
        return FeedbackSourceNames[originService.toLowerCase()] ?? "?";
    }
  })();

  return `${base}${
    sourceCreatedAt == null || !withDate
      ? " "
      : ` (${dayjs(sourceCreatedAt).format("MMM D, hh:mm")})`
  }`;
};

/**
 * @deprecated This function is deprecated and will be removed in future versions.
 * Please use `getSourceName` instead.
 */
export const getSourceNameOld = (
  source: FeedbackSources,
  withDate: boolean = true,
  withCountry = true
): string => {
  const base = (() => {
    const sourceName = source.data.name;

    if (source.type === FeedbackSourceType.all) {
      return "All sources";
    }
    if (source.type === FeedbackSourceType.freeFormText) {
      return sourceName ?? `Text`;
    }
    if (source.type === FeedbackSourceType.csv) {
      return sourceName ?? `CSV`;
    }
    if (source.type === FeedbackSourceType.twitter && "search" in source.data.extra) {
      return sourceName ?? `X aka Twitter (${source.data.extra["search"]})`;
    }
    if (source.type === FeedbackSourceType.zapier) {
      return sourceName ?? `${FeedbackSourceNames[source.type]}`;
    }
    if (source.type === FeedbackSourceType.appNew) {
      const { country } = source.data.extra;
      return typeof country === "string" && withCountry
        ? `${sourceName} (${country.toUpperCase()})`
        : sourceName ?? "?";
    }
    if (source.type === FeedbackSourceType.group) {
      return sourceName ?? FeedbackSourceNames.group;
    }
    if (source.type === FeedbackSourceType.g2) {
      return sourceName ?? `G2 - ${source.data.extra?.productSlug}`;
    }
    if (source.type === FeedbackSourceType.trustpilot) {
      return sourceName ?? `Trustpilot - ${source.data.extra?.productSlug}`;
    }
    if (source.type === FeedbackSourceType.reddit) {
      return sourceName ?? `Reddit - ${source.data.extra?.productSlug}`;
    }
    if (source.type === FeedbackSourceType.amazon) {
      return sourceName ?? `Amazon - ${source.data.extra?.productSlug}`;
    }
    if (source.type === FeedbackSourceType.twitter) {
      return sourceName ?? `X aka Twitter - ${source.data.extra?.productSlug}`;
    }

    if (source.data.extra != null && source.data.integrationService === "FIVETRAN") {
      const sourceExtra = source.data.extra as FeedbackSourceExtraFivetran;
      const retrievedTags = sourceExtra.tags ?? [];

      if (retrievedTags.length > 0) {
        return (
          sourceName ?? `${FeedbackSourceNames[source.type]} (tags: ${retrievedTags.join(",")})`
        );
      } else {
        return sourceName ?? `${FeedbackSourceNames[source.type]}`;
      }
    }

    return sourceName ?? FeedbackSourceNames[source.type] ?? "?";
  })();

  return `${base}${
    source.createdAt == null || !withDate
      ? " "
      : ` (${dayjs(source.createdAt).format("MMM D, hh:mm")})`
  }`;
};

export const getSourceId = (source: FeedbackSources): string => {
  return String(source.data.id);
};

export const getSortedSources = (sources: FeedbackSources[]): FeedbackSources[] => {
  const sortedByName = sortBy(sources, (source) => getSourceNameOld(source));
  // Sort by descending createdAt date (most recently created first)
  const sortedByCreatedDate = reverse(
    sortBy(sortedByName, (source) => source.createdAt.toISOString())
  );

  return sortBy(sortedByCreatedDate, (source) => {
    if (isFeedbackSourceAppNew(source)) {
      return getAppstoreType(source.data.extra.appId) === "ios" ? 3 : 4;
    } else if (isFeedbackSourceZendesk(source)) {
      return 5;
    } else if (isFeedbackSourceFront(source)) {
      return 6;
    } else if (isFeedbackSourceGithub(source)) {
      return 7;
    } else if (isFeedbackSourceG2(source)) {
      return 8;
    } else if (isFeedbackSourceFreshdesk(source)) {
      return 9;
    } else if (isFeedbackSourceHubspot(source)) {
      return 10;
    } else if (isFeedbackSourceKustomer(source)) {
      return 11;
    } else if (isFeedbackSourceIntercom(source)) {
      return 12;
    } else if (isFeedbackSourceStackOverflow(source)) {
      return 13;
    } else if (isFeedbackSourceTwitter(source)) {
      return 14;
    } else if (isFeedbackSourceSalesForce(source)) {
      return 15;
    } else if (isFeedbackSourceDixa(source)) {
      return 16;
    } else if (isFeedbackSourceHackerNews(source)) {
      return 17;
    } else if (isFeedbackSourceGongCalls(source)) {
      return 18;
    } else if (isFeedbackSourceCsv(source)) {
      return 19;
    } else if (isFeedbackSourceFreeFormText(source)) {
      return 20;
    } else if (isFeedbackSourceJira(source)) {
      return 21;
    } else if (isFeedbackSourceZapier(source)) {
      return 22;
    } else if (isFeedbackSourceAllSources(source)) {
      return 23;
    }
    // Other
    return 24;
  });
};

export const FeedbackSourceTypesSchema = z.union([
  z.nativeEnum(FeedbackSourceType),
  z.literal("app"),
]);

export const getSourceSummaryAnalysisStatus = ({
  reviews,
}: FeedbackReviewSummaryData): AnalysisStatus => {
  // This logic should match getAnalysisStatus from Klassy
  if (reviews.complaints.length > 0 || reviews.requests.length > 0) {
    return "analysis-complete";
  }

  return "analyzing-for-final-results";
};

export const getAnalysisItemNameForSourceType = (
  sourceType: FeedbackSourceType | "app",
  plural = true
) => {
  if (sourceType === "all") return plural ? "sources" : "source";
  if (sourceType === "group") return plural ? "reviews" : "review";
  if (sourceType === "app") return plural ? "reviews" : "review";
  if (sourceType === "appNew") return plural ? "reviews" : "review";
  if (sourceType === "facebook") return plural ? "posts" : "post";
  if (sourceType === "instagram") return plural ? "posts" : "post";
  if (sourceType === "csv") return plural ? "rows" : "row";
  if (sourceType === "twitter") return plural ? "tweets" : "tweet";
  if (sourceType === "hackerNews") return plural ? "posts" : "post";
  if (sourceType === "zapier") return plural ? "zaps" : "zap";
  if (sourceType === "free-form-text") return plural ? "snippets" : "snippet";
  if (sourceType === "survey") return plural ? "responses" : "response";
  if (sourceType === "g2") return plural ? "reviews" : "review";

  return plural ? "tickets" : "ticket";
};

export const isReverseAnalysisSource = (sourceType?: FeedbackSourceType | "app") => {
  // "All" and free text sources do not show reverse analysis
  return (
    sourceType !== FeedbackSourceType.all &&
    sourceType !== FeedbackSourceType.freeFormText &&
    sourceType !== FeedbackSourceType.group
  );
};

const SOURCE_FRESH_THRESHOLD_SECONDS = 5 * 60;

export const isSourceStillFresh = (
  source?: FeedbackSources | null,
  freshnessSeconds = SOURCE_FRESH_THRESHOLD_SECONDS
): boolean => {
  if (source == null) return false;

  const freshCutoff = dayjs(source.createdAt).add(freshnessSeconds, "seconds");

  const isFresh = dayjs().isBefore(freshCutoff);

  return isFresh;
};

const callTranscriptSubtypes = new Set<string>(["transcripts", "calls"]);

export function isCallTranscript(subtype?: string): boolean {
  return subtype ? callTranscriptSubtypes.has(subtype) : false;
}

export function isSupportSource(source: FeedbackSources): boolean {
  return (
    source.type === FeedbackSourceType.zapier_intercom ||
    source.type === FeedbackSourceType.zapier_zendesk ||
    source.type === FeedbackSourceType.hubspot ||
    source.type === FeedbackSourceType.helpscout ||
    source.type === FeedbackSourceType.front ||
    source.type === FeedbackSourceType.intercom ||
    source.type === FeedbackSourceType.zendesk ||
    source.type === FeedbackSourceType.slack
  );
}

export function isCallTranscriptSource(source: FeedbackSources): boolean {
  return source.type === FeedbackSourceType.gongCalls;
}

export function getVisibleTerms(
  terms: Array<FeedbackAnalysisItem>,
  isSourceStillFresh: boolean,
  isCallTranscriptSource: boolean
): Array<FeedbackAnalysisItem> {
  let updateTermList: Array<FeedbackAnalysisItem> = terms
    .filter((t) => !t.deletedAt)
    .map((t) => ({ ...t, term: t.updatedText ?? t.text }));

  // If the source is still fresh we want to show all items regardless of mentions
  if (isSourceStillFresh) {
    return updateTermList;
  }

  // If any terms have mentions show only those terms
  // Except for call transcripts
  if (!isCallTranscriptSource && updateTermList.some((term) => term.mentions > 0)) {
    updateTermList = updateTermList.filter((term) => term.mentions > 0);
  }

  // If it's a call transcript then Klassy used special prompts that output summaries
  // in a most-important-first order. We don't want RA to override that order.
  // The reason is that in a call transcript a person can mention something insignificant multiple times
  // but something significant just once. Basically if a person talks about it a lot
  // it doesn't mean it's the most important thing for them.
  if (isCallTranscriptSource) {
    return sortBy(updateTermList, [(t) => t.sortOrder, (t) => t.rank]);
  }

  return sortBy(updateTermList, [(t) => t.sortOrder, (t) => -t.mentions]);
}

export function convertWordCountToTokenCount(wordCount: number): number {
  return Math.ceil(wordCount * 1.3);
}

export function convertTokenCountToWordCount(tokenCount: number): number {
  return Math.floor(tokenCount / 1.3);
}

export const getSelectedSource = (
  feedbackSources: GetFeedbackSourcesResponse,
  param?: number
): FeedbackSources | null => {
  if (feedbackSources.sources) {
    const sortedSources = getSortedSources(feedbackSources.sources);
    if (param != null) {
      const queryValue = Array.isArray(param) ? param[0] : param;
      if (!queryValue) {
        return sortedSources[0] || null;
      }
      const selectedSource = sortedSources.find(
        (source) => source.data.id === parseInt(queryValue)
      );
      return selectedSource || null;
    } else {
      return sortedSources[0] || null;
    }
  }
  return null;
};

const analysisTypesList = Object.values(AnalysisType);

export const getAnalysisType = (source: FeedbackSources): AnalysisType => {
  const currentSource = source as FeedbackSourceData<FeedbackSourceExtra>;
  const type = currentSource?.data?.extra?.analysisType;
  if (isAnalysisType(type)) return type as AnalysisType;
  else {
    return AnalysisType.regular;
  }
};

function isAnalysisType(analysisType?: any): boolean {
  return analysisType ? analysisTypesList.includes(analysisType) : false;
}

export const getAnalysisTypeName = (analysisType: string): string => {
  if (!analysisType) return "Kraftful Analysis";
  switch (analysisType) {
    case AnalysisType.secondary:
      return "Kraftful 2 Analysis";
    case AnalysisType.swot:
      return "SWOT Analysis";
    case AnalysisType.empathy:
      return "Empathy Map Analysis";
    case AnalysisType.what:
      return "Four Whats Analysis";
    default:
      return "Kraftful Analysis";
  }
};

export const analyzingOlderFeedback = (source: FeedbackSources) => {
  if (source.type === FeedbackSourceType.appNew) {
    const extra = source.data.extra as FeedbackSourceExtraApp;
    if (extra?.download1YearReviews) {
      return extra?.download1YearReviews;
    }
  }
  return false;
};

/**
 * Derives parent sources (i.e. groups that contain the target source)
 * @param targetSource The target child source
 * @param sources All available sources
 * @returns Array of parent sources. Returns empty if no parent sources exist
 *
 * @deprecated Group relationships (parent/child relationships) are derived from the source-to-source join table
 */
export function getAssociatedGroupSources(
  targetSource: FeedbackSources | null,
  sources: FeedbackSources[]
): FeedbackSources[] {
  if (targetSource === null || isFeedbackSourceGroup(targetSource)) {
    return [];
  }

  const asGroupSource = (s: FeedbackSources) => s as FeedbackSourceData<FeedbackSourceExtraGroup>;
  return sources.filter(
    (source) =>
      isFeedbackSourceGroup(source) &&
      asGroupSource(source).data.extra.upstreamSourceIds.includes(targetSource.data.id)
  );
}
